import React from "react";
import "../src/App.css";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import Main from "../src/Components/Main/Main";

const App = () => {
  return (
    <>
      <div className="Navbar_Div">
        <Navbar />
      </div>
      <div className="Home_Div">
        <Home />
      </div>
      <div className="Main_Div">
        <Main />
      </div>
      <div className="Footer_Div">
        <Footer />
      </div>
    </>
  );
};

export default App;
